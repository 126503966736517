header {
    width: 100%;
    /* position: relative; */
    /* background: rgb(220, 158, 158); */
}

header nav {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    justify-content: space-between;
    padding: 0px 7.5vw;
    color: #252525;

}

.nv-l {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nv-l img {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
    height: 80px !important;
    }

    .nv-l span {
        font-size: 1.1rem;
        font-family: var(--font2);

        /* font-family: var(--font6); */
}

.nv-r {
    display: flex;
    align-items: center;
    gap: 25px;
    width: 100%;
    justify-content: center;
}

.nv-r>* {
    color: #252525;
    /* color: #252525; */
    text-decoration: none;
    font-size: 0.8rem;
    font-family: var(--font6);
    font-weight: 600 !important;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.hamburger {
    color: #252525 !important;
}


.clo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    cursor: pointer;

}

.nv-r-h>* {
    color: #252525;
    text-decoration: none;
}

.nv-r-h {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 35px;
    flex-direction: column;
}
.hidden-side-nav.show {
    right: -25px;
}
.hidden-side-nav {
    background: #ffffff;
    height: 100vh;
    width: max(auto, 300px);
    max-width: 400px;
    min-width: 250px;
    position: fixed;
    right: -500px;
    z-index: 111;
    top: 0;
    padding: 35px;
    padding-right: 60px;
    display: flex;
    transition: all .6s cubic-bezier(.175, .885, .32, 1.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    /* border: 3px solid lime; */
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.nv-l-hid {
    display: none;
}

.nv-l-hid img {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
    height: 80px !important;
}
@media (max-width: 768px) {
    .nv-r {
        display: none !important;
    }
    .nv-l-hid {
        display: inline-block;
    }
}

@media (max-width: 500px) {
    .nv-l img {
        height: 90px;
        transform: scale(1.4);
    }
}
@media (min-width: 768px) {
    .menu-button {
        display: none !important;
    }

    .hidden-side-nav {
        display: none;
    }
}