footer {
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 7.5vh;
}
footer #ft-lg img {
    height: 200px;
    margin-top: 15px;
    margin-bottom: -25px;
    transform: scale(1.5) !important;

}
footer b {
    margin: 25px;
    margin-top: 10px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-family: var(--font6);
}
#g-para {
    display: flex;
    flex-direction: column;
    text-align: center;
}
#g-para p:hover {
    color: #787878 !important;
}

    #g-para>* {
        font-size: 0.8rem;
        color: #787878;
        font-family: var(--font6) !important;
}
#xl-ico {
    transform: scale(0.8);
    margin-right: -2px;
}
#soc>*>* {
    font-size: 1rem !important;
    color: #303030;
    transition: 0.3s ease-in-out;
}
#soc>*:hover {
    background-color: #303030;
}
#soc>*:hover * {
    color: #eee;
}

    #soc>* {
        height: 45px;
        width: 45px;
        border: 1px solid #303030;
        display: grid;
        place-items: center;
        border-radius: 50%;
        transition: 0.3s ease-in-out;

}
#soc {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin: 15px 0;
    margin-bottom: 10vh;
}

#cp {
    background-color: #ddd;
    width: 100%;
    padding: 15px;
    font-size: 0.85rem;
    text-align: center;
    color: #545454;
    font-family: var(--font6);
}
@media (max-width: 768px) {
}

@media (max-width: 500px) {
    footer #ft-lg img {
        height: 170px;

  }
}

@media (min-width: 768px) {
}