* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    scroll-behavior: smooth;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Michroma&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Prompt:wght@100;200;300;400;500;600;700;800;900&family=Russo+One&family=Saira:wght@100;200;300;400;500;600;700;800;900&family=Tilt+Warp&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Michroma&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Prompt:wght@100;200;300;400;500;600;700;800;900&family=Russo+One&family=Saira:wght@100;200;300;400;500;600;700;800;900&family=Tilt+Warp&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=WindSong:wght@400;500&display=swap');

:root {
    /* --font1: 'Anton', sans-serif; */
    --font2: 'Michroma',
    sans-serif;
    --font3: 'Poppins',
    sans-serif;
    --font4: 'Prompt',
    sans-serif;
    --font5: 'Russo One',
    sans-serif;
    --font6: 'Saira',
    sans-serif;
    --font7: 'Tilt Warp',
    cursive;
    --font8: 'Kanit',
    sans-serif;
    --gray: #aaa;
    --blue2: #5865F2;
    /* --blue2: lime; */
    /* --green: #D6FB00; */
    --blue: #003B49;
    --blue2: #092d35;
    /* --org: #EC9A96; */
    --org: #d37e05;
    --font1: 'WindSong',
        cursive;
        }

        .lato-thin {
            font-family: "Lato", sans-serif;
            font-weight: 100;
            font-style: normal;
        }

        .lato-light {
            font-family: "Lato", sans-serif;
            font-weight: 300;
            font-style: normal;
        }

        .lato-regular {
            font-family: "Lato", sans-serif;
            font-weight: 400;
            font-style: normal;
        }

        .lato-bold {
            font-family: "Lato", sans-serif;
            font-weight: 700;
            font-style: normal;
        }

        .lato-black {
            font-family: "Lato", sans-serif;
            font-weight: 900;
            font-style: normal;
        }

        .lato-thin-italic {
            font-family: "Lato", sans-serif;
            font-weight: 100;
            font-style: italic;
        }

        .lato-light-italic {
            font-family: "Lato", sans-serif;
            font-weight: 300;
            font-style: italic;
        }

        .lato-regular-italic {
            font-family: "Lato", sans-serif;
            font-weight: 400;
            font-style: italic;
        }

        .lato-bold-italic {
            font-family: "Lato", sans-serif;
            font-weight: 700;
            font-style: italic;
        }

        .lato-black-italic {
            font-family: "Lato", sans-serif;
            font-weight: 900;
            font-style: italic;
}
@import url('https://fonts.googleapis.com/css2?family=WindSong:wght@400;500&display=swap');
*::-webkit-scrollbar {
    width: 11px;

}

*::-webkit-scrollbar-track {
    background: #252525;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--org);
    /* cursor: grab !important; */
    border-radius: 11px;
    box-shadow: 0 0 50px 5px rgba(255, 205, 180, 0.9);
    /* outline: 1px solid slategrey; */
}
.servicebackgrandV{
    background-size: cover !important;
    background-position: top !important;
}
html,
    body {
        height: auto;
        width: 100dvw;
        overflow-x: hidden;
        }

        ::-moz-selection {
            color: #fff;
            background: #252525;
        }

        ::selection {
            color: #fff;
            background: #252525;
        }
